import ApiService from "@/api/users";

// GET USERS
const getUsers = (context, user) => {
  return new Promise((resolve, reject) => {
    ApiService.getUsers(user).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

// GET WINRATE STATS
const getStats = () => {
  return new Promise((resolve, reject) => {
    ApiService.getStats().then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

// UPDATE USER
const updateUser = (context, user) => {
  return new Promise((resolve, reject) => {
    ApiService.updateUser(user).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

// DELETE USER
const deleteUser = (context, user) => {
  return new Promise((resolve, reject) => {
    ApiService.deleteUser(user).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export default {
  getUsers,
  getStats,
  updateUser,
  deleteUser,
};
