<template>
  <component :is="dynamicComponent" />
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
  name: "NavIcon",
  props: {
    icon: String,
  },
  components: {},
  data: () => {},
  created() {},
  computed: {
    dynamicComponent() {
      const icon = this.icon;

      return defineAsyncComponent(() => import(`../icons/${icon}.vue`));
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
#nav-icon {
}
</style>
