import ApiService from "@/api/clans";

// GET CLANS
const getClans = (context, clan) => {
  return new Promise((resolve, reject) => {
    ApiService.getClans(clan).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

// GET CLAN STATS
const getStats = () => {
  return new Promise((resolve, reject) => {
    ApiService.getStats().then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

// UPDATE CLAN
const updateClan = (context, clan) => {
  return new Promise((resolve, reject) => {
    ApiService.updateClan(clan).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

// DELETE CLAN
const deleteClan = (context, clan) => {
  return new Promise((resolve, reject) => {
    ApiService.deleteClan(clan).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export default {
  getClans,
  getStats,
  updateClan,
  deleteClan,
};
