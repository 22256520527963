<template>
  <div>
    <!-- Loader -->
    <loader-component v-if="loading"></loader-component>
    <!-- <locale-switcher></locale-switcher> -->
    <router-view v-slot="{ Component }">
      <transition name="route" mode="fade">
        <component :is="Component"></component>
      </transition>
    </router-view>
  </div>
</template>

<script>
import LocaleSwitcher from "@/components/global/LocaleSwitcher.vue";
import LoaderComponent from "@/components/global/GlobalLoader.vue";
import axios from "axios";
import store from "./store";
import { mapActions, mapState } from "vuex";
export default {
  name: "App",
  props: {},
  components: {
    LocaleSwitcher,
    LoaderComponent,
  },
  data() {
    return {};
  },
  created() {
    axios.interceptors.request.use(
      (config) => {
        if (config.showLoader) {
          store.dispatch("loader/pending");
        }
        return config;
      },
      (error) => {
        if (error.config.showLoader) {
          store.dispatch("loader/done");
        }

        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      (response) => {
        if (response.config.showLoader) {
          store.dispatch("loader/done");
        }

        return response;
      },
      (error) => {
        let response = error.response;

        if (response.config.showLoader) {
          store.dispatch("loader/done");
        }

        if (error.response.status === 401) {
          this.logOut();
          this.$router.push("/login");
        }
        return Promise.reject(error);
      }
    );
  },
  computed: {
    ...mapState("loader", ["loading"]),
    metaTitle() {
      if (
        this.$route.meta !== undefined &&
        this.$route.meta.metaTags !== undefined
      ) {
        return this.$t(this.$route.meta.metaTags.title);
      }
      return "Superhero App";
    },
  },
  mounted() {
    document.title = this.metaTitle;
  },
  watch: {
    "$route.path"() {
      //document.title = this.metaTitle;
      console.log(this.metaTitle);
      document.title = this.metaTitle;
    },
  },
  methods: {
    ...mapActions({
      logOut: "auth/logOut",
    }),
  },
};
</script>

<style>
#app {
  font-family: "Mulish", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

/* route transitions */
.route-enter-from {
  opacity: 0;
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.route-leave-to {
  opacity: 0;
}

.route-leave-active {
  transition: all 0.3s ease-in;
}
</style>
