import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import i18n from "./lang";
import axios from "axios";
import mitt from "mitt";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { dom } from "@fortawesome/fontawesome-svg-core";
dom.watch();
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas, far);

const app = createApp(App);
const emitter = mitt();
app.config.globalProperties.$moment = moment;
app.config.globalProperties.emitter = emitter;

axios.defaults.showLoader = true;

app
  .use(store)
  .use(router)
  .use(i18n)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
