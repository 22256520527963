import AuthService from "@/api/auth";

const loginUser = (context, user) => {
  return new Promise((resolve, reject) => {
    AuthService.loginUser(user).then(
      (res) => {
        context.commit("SET_AUTH_TOKEN", res.apiKey);
        context.commit("SET_USER_PROFILE", res);
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

const logOut = (context) => {
  context.commit("RESET_USER_STATE");
};

export default {
  loginUser,
  logOut,
};
