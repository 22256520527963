import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
const routes = [
  {
    path: "",
    component: () => import("../layouts/mainlayouts/Authenticated.vue"),
    children: [
      {
        path: "",
        name: "Overview",
        meta: {
          authenticated: true,
          title: "app.overview.title",
          metaTags: {
            title: "app.overview.title",
            description: "",
            ogDescription: "",
          },
        },
        component: () => import("../views/Overview.vue"),
      },
      {
        path: "/users",
        name: "Users",
        meta: {
          authenticated: true,
          title: "app.users.title",
          metaTags: {
            title: "app.users.title",
            description: "",
            ogDescription: "",
          },
        },
        component: () => import("../views/Users.vue"),
      },
      {
        path: "/clans",
        name: "Clans",
        meta: {
          authenticated: true,
          title: "app.clans.title",
          metaTags: {
            title: "app.clans.title",
            description: "",
            ogDescription: "",
          },
        },
        component: () => import("../views/Clans.vue"),
      },
      {
        path: "/heroes",
        name: "Heroes",
        meta: {
          authenticated: true,
          title: "app.heroes.title",
          metaTags: {
            title: "app.heroes.title",
            description: "",
            ogDescription: "",
          },
        },
        component: () => import("../views/Heroes.vue"),
      },
    ],
  },
  {
    path: "",
    component: () => import("../layouts/mainlayouts/UnAuthenticated.vue"),
    children: [
      {
        path: "/login",
        name: "Login",
        meta: {
          authenticated: false,
          title: "app.login.title",
          metaTags: {
            title: "app.login.title",
            description: "",
            ogDescription: "",
          },
        },
        component: () => import("../views/auth/LoginScreen.vue"),
      },
      {
        path: "/404",
        name: "Error404",
        component: () => import("../layouts/layoutcomponents/Error404.vue"),
        meta: {
          authenticated: false,
          title: "app.error.title",
          metaTags: {
            title: "app.error.title",
            description: "",
            ogDescription: "",
          },
        },
      },
    ],
  },
  {
    //path: "/:pathMatch(.*)*",
    path: "/:catchAll(.*)",
    beforeEnter(to, from, next) {
      next("/404");
    },
    meta: {
      authenticated: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "active",
  scrollBehavior() {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  },
});

const guard = (to, from, next) => {
  const authenticated = to.meta.authenticated;
  const hasAuthToken = store.getters["auth/hasAuthToken"];

  if (authenticated) {
    if (hasAuthToken) return next();
    return next("/login");
  } else {
    if (hasAuthToken) return next("/users");
    return next();
  }
};

router.beforeEach(guard);

export default router;
