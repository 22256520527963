import ApiService from "@/api/heroes";

// GET HEROES
const getHeroes = (context, hero) => {
  return new Promise((resolve, reject) => {
    ApiService.getHeroes(hero).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

// GET HERO STATS
const getStats = () => {
  return new Promise((resolve, reject) => {
    ApiService.getStats().then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

// UPDATE HERO
const updateHero = (context, hero) => {
  return new Promise((resolve, reject) => {
    ApiService.updateHero(hero).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

// DELETE HERO
const deleteHero = (context, hero) => {
  return new Promise((resolve, reject) => {
    ApiService.deleteHero(hero).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export default {
  getHeroes,
  getStats,
  updateHero,
  deleteHero,
};
