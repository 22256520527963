import { fetchData, apiURL } from "./api_helper";

// User API services

// GET USERS
const getUsers = ([
  page,
  limit,
  sortBy,
  sortDir,
  search,
  filter,
  needAuthToken,
]) => {
  return new Promise((resolve, reject) => {
    let endpoint =
      apiURL +
      `/users?page=${page}&limit=${limit}&sort=${
        sortBy ? sortBy : ""
      }&sortdir=${sortDir ? sortDir : ""}&name=${
        search ? search : ""
      }&winrate=${filter ? filter : ""}`;

    fetchData(endpoint, "get", null, needAuthToken, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// GET WINRATE STATS
const getStats = (needAuthToken) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/users/stats";

    fetchData(endpoint, "get", null, needAuthToken, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// UPDATE USER
const updateUser = ([userID, requestBody, needAuthToken]) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/users/${userID}`;

    fetchData(endpoint, "put", requestBody, needAuthToken, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// DELETE USER
const deleteUser = ([userID, needAuthToken]) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/users/${userID}`;

    fetchData(endpoint, "delete", null, needAuthToken, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default {
  getUsers,
  getStats,
  updateUser,
  deleteUser,
};
