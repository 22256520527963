const SET_USER_PROFILE = (state, data) => {
  state.loggedInUserProfile = data;
};

const SET_AUTH_TOKEN = (state, data) => {
  state.authToken = data;
};

const RESET_USER_STATE = (state) => {
  state.authToken = null;
  state.loggedInUserProfile = null;
};

export default {
  SET_USER_PROFILE,
  SET_AUTH_TOKEN,
  RESET_USER_STATE,
};
