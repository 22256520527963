<template>
  <div class="loader-overlay">
    <figure>
      <div class="dot white"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <svg-icon icon="Loader" class="loader-icon-svg"></svg-icon>
    </figure>
  </div>
</template>

<script>
import SvgIcon from "@/components/global/SvgIcon.vue";
export default {
  name: "LoaderComponent",
  props: {
    loading: { type: Boolean, required: true },
  },
  components: {
    SvgIcon,
  },
  data: () => ({}),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss">
.loader-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10000;
}

.loader-overlay figure {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 6.25em;
  height: 6.25em;
  z-index: 9998;
}

.loader-icon-svg {
  position: relative;
  top: 28px;
  left: 3px;
}

.white {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  animation: flash 2.4s linear infinite;
  opacity: 0;
}
.dot {
  position: absolute;
  margin: auto;
  width: 5.4em;
  height: 5.4em;
  border-radius: 100%;
  transition: all 1s ease;
}
.dot:nth-child(2) {
  top: 0;
  bottom: 0;
  left: 0;
  background: #3751ff;
  animation: dotsY 2.4s linear infinite;
}
.dot:nth-child(3) {
  left: 0;
  right: 0;
  top: 0;
  background: #3751ff;
  animation: dotsX 2.4s linear infinite;
}
.dot:nth-child(4) {
  top: 0;
  bottom: 0;
  right: 0;
  background: #3751ff;
  animation: dotsY 2.4s linear infinite;
}
.dot:nth-child(5) {
  left: 0;
  right: 0;
  bottom: 0;
  background: #3751ff;
  animation: dotsX 2.4s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  10% {
    width: 6.25em;
    height: 6.25em;
  }
  66% {
    width: 2.4em;
    height: 2.4em;
  }
  100% {
    transform: rotate(360deg);
    width: 6.25em;
    height: 6.25em;
  }
}

@keyframes dotsY {
  66% {
    opacity: 0.1;
    width: 2.4em;
  }
  77% {
    opacity: 1;
    width: 0;
  }
}
@keyframes dotsX {
  66% {
    opacity: 0.1;
    height: 2.4em;
  }
  77% {
    opacity: 1;
    height: 0;
  }
}

@keyframes flash {
  33% {
    opacity: 0;
    border-radius: 0%;
  }
  55% {
    opacity: 0.6;
    border-radius: 100%;
  }
  66% {
    opacity: 0;
  }
}
</style>
