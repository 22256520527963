import { createStore } from "vuex";
import users from "./modules/users";
import clans from "./modules/clans";
import heroes from "./modules/heroes";
import auth from "./modules/auth";
import { loader } from "./modules/loader";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    users,
    clans,
    heroes,
    loader,
  },
  plugins: [
    createPersistedState({
      key: "store",
      paths: ["auth"],
    }),
  ],
});
