import { fetchData, apiURL } from "./api_helper";

// Auth API services

// LOGIN

const loginUser = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/login";

    fetchData(endpoint, "post", requestBody, false, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default {
  loginUser,
};
