<template>
  <div id="locale-switcher">
    <ul>
      <li v-for="locale in locales" :key="locale" @click="switchLocale(locale)">
        {{ locale }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "LocaleSwitcher",
  props: {},
  components: {},
  data() {
    return {
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(","),
    };
  },
  created() {
    console.log(this.locales);
  },
  computed: {},
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale != locale) this.$i18n.locale = locale;
    },
  },
};
</script>

<style lang="scss" scoped>
#locale-switcher {
}

li {
  text-decoration: underline;
  color: #459ce7;
  cursor: pointer;
}
</style>
