import store from "@/store";
import axios from "axios";

axios.defaults.showLoader = true;

const apiURL = process.env.VUE_APP_API_URL;

function fetchData(
  endpoint,
  requestType,
  requestBody,
  needAuthToken = true,
  showLoader = true
) {
  const authToken = store.getters["auth/getAuthToken"];
  const headers = {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    "Auth-Token": authToken,
  };

  // kell token

  if (needAuthToken != undefined && needAuthToken == false) {
    // nem kell token
    needAuthToken = false;
  }

  if (showLoader != undefined && showLoader == false) {
    showLoader = false;
  }

  const axiosConfig = {
    method: requestType.toLowerCase(),
    url: endpoint,
    headers: headers,
    showLoader: showLoader,
  };

  if (requestBody) {
    if (["get"].includes(requestType.toLowerCase())) {
      axiosConfig.params = requestBody;
    } else {
      axiosConfig.data = requestBody;
    }
  }

  return axios(axiosConfig)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export { fetchData, apiURL };
