import { fetchData, apiURL } from "./api_helper";

// Hero API services

// GET HEROES
const getHeroes = ([
  page,
  limit,
  sortBy,
  sortDir,
  search,
  filter,
  needAuthToken,
]) => {
  return new Promise((resolve, reject) => {
    let endpoint =
      apiURL +
      `/heroes?page=${page}&limit=${limit}&sort=${
        sortBy ? sortBy : ""
      }&sortdir=${sortDir ? sortDir : ""}&name=${search ? search : ""}`;

    fetchData(endpoint, "get", null, needAuthToken, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// GET HERO STATS
const getStats = (needAuthToken) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/heroes/stats";

    fetchData(endpoint, "get", null, needAuthToken, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// UPDATE HERO
const updateHero = ([heroID, requestBody, needAuthToken]) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/heroes/${heroID}`;

    fetchData(endpoint, "put", requestBody, needAuthToken, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// DELETE HERO
const deleteHero = ([heroID, needAuthToken]) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/heroes/${heroID}`;

    fetchData(endpoint, "delete", null, needAuthToken, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default {
  getHeroes,
  getStats,
  updateHero,
  deleteHero,
};
