import { fetchData, apiURL } from "./api_helper";

// Clan API services

// GET CLANS
const getClans = ([
  page,
  limit,
  sortBy,
  sortDir,
  search,
  filter,
  maxMembers,
  needAuthToken,
]) => {
  return new Promise((resolve, reject) => {
    let endpoint =
      apiURL +
      `/clans?page=${page}&limit=${limit}&sort=${
        sortBy ? sortBy : ""
      }&sortdir=${sortDir ? sortDir : ""}&name=${search ? search : ""}${
        filter
          ? `&min=${filter[0]}&max=${filter[1]}`
          : `&min=1&max=${maxMembers}`
      }`;

    fetchData(endpoint, "get", null, needAuthToken, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// GET CLAN STATS
const getStats = (needAuthToken) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/clans/stats";

    fetchData(endpoint, "get", null, needAuthToken, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// UPDATE CLAN
const updateClan = ([clanID, requestBody, needAuthToken]) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/clans/${clanID}`;

    fetchData(endpoint, "put", requestBody, needAuthToken, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// DELETE CLAN
const deleteClan = ([clanID, needAuthToken]) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/clans/${clanID}`;

    fetchData(endpoint, "delete", null, needAuthToken, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default {
  getClans,
  getStats,
  updateClan,
  deleteClan,
};
